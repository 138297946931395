import FileUpload from "./modules/FileUpload";
import ClickSpark from './components/click-spark/ClickSpark'

const mainEl = document.querySelector('main');
const uploadContainer = document.getElementById('hero') as HTMLElement;
const fileInput = <HTMLInputElement>document.getElementById('file_upload');
const form = <HTMLFormElement>document.getElementById("upload_form")

let originalDropMessage = '';
const drop_error_message = document.getElementById('dragoverMessage');

if (drop_error_message) {
    originalDropMessage = drop_error_message.textContent;
}

const progress_message = <HTMLElement>document.getElementById('message');
let originalMessage = progress_message.textContent;
const subMessage = <HTMLElement>document.querySelector('#fullScreenMessage h4');


const getTimeOutBasedOnLength = (length: number) => {
    return Math.ceil(((((length / 5) / 180) * 60) * 1000) + 1500);
}

const successAnimation = (timeout: number) => {
    updateContainerHeight();
    // To continuously create hearts
    let interval = setInterval(createHeart, 50); // Adjust interval as needed
    setTimeout(() => {
        clearTimeout(interval)
        mainEl.classList.add('fade');
    }, timeout)
}


if (uploadContainer) {
    const fileUpload = new FileUpload(uploadContainer, form, fileInput);

    document.addEventListener('audioUploadError', (event: CustomEvent) => {
        let timeout = getTimeOutBasedOnLength(event.detail.message.length);
        drop_error_message.textContent = event.detail.message;
        uploadContainer.classList.add('error');
        mainEl.classList.remove('uploading');
        setTimeout(() => {
            drop_error_message.textContent = originalDropMessage;
            progress_message.textContent = originalMessage;
            subMessage.style.display = null;
            uploadContainer.classList.remove('error');
            fileUpload.removeDragView();
        }, timeout)
    });


    document.addEventListener('audioUploadProgress', (event: CustomEvent) => {
        mainEl.classList.add('uploading');
    });


    document.addEventListener('audioUploadSuccess', (event: CustomEvent) => {

        let redirect = event.detail.redirect;

        mainEl.classList.add('success');

        successAnimation(2000);

        setTimeout(() => {

            // Fallback for browsers that don't support this API:
            // @ts-ignore
            if (!document.startViewTransition) {
                window.location.assign(redirect);
                return;
            }

            // With a transition:
            // @ts-ignore
            document.startViewTransition(() => window.location.assign(redirect));

        }, 2500)

    });

}

// Click Spark On loading = Fun!
if (!customElements.get("click-spark")) {
    customElements.define("click-spark", ClickSpark);
}


// main.addEventListener('audioFileDropped', handleCardUpdate);    /**
//      * @param {CustomEvent} event
//      */
//     public handleCardUpdate(event) {
//         const {file} = event.detail;
//         if (file) {
//             profileCard.querySelector("img").src = image;
//         }
//     }

/*
|--------------------------------------------------------------------------
| Success Hearts
|--------------------------------------------------------------------------
*/

document.addEventListener("DOMContentLoaded", function () {
    if (mainEl.classList.contains('success')) {
        successAnimation(2500);
    }
});

const container = document.getElementById('loading_block');
const originalHeart = document.querySelector('.flyingBubble') as Element;

function updateContainerHeight() {
    const height = container.offsetHeight;
    document.documentElement.style.setProperty('--container-height', `-${height}px`);
}

// Update on load and resize
updateContainerHeight();
window.addEventListener('resize', updateContainerHeight);

function createHeart() {
    const heart = originalHeart.cloneNode(true);
    if (heart instanceof SVGElement) {
        //--color - 1
        heart.style.position = 'absolute';
        heart.style.bottom = '0';
        heart.style.left = `${Math.random() * 100}%`;
        // Random duration between 1 to 5 seconds
        const duration = Math.random() * 2 + 1;
        heart.style.scale = (Math.random() + 1).toString();

        const colorNumber = Math.floor(Math.random() * 8) + 1; // Random number between 1 and 8
        heart.style.fill = `var(--color-${colorNumber})`;

        heart.style.animation = `moveUp ${duration}s linear`;
        container.appendChild(heart);
        // Remove heart after animation
        setTimeout(() => {
            heart.remove();
        }, duration * 1000);
    }

}



